import Web3 from 'web3';
window.web3 = new Web3(window.ethereum);

export const metamaskInstalled = () => {
  if (typeof window.ethereum !== 'undefined') {
    return 'MetaMask esta instalado'
  } else return 'Metamask no instalado'
};

const networks = {
  binance: {
    id: '0x38',
    chainName: 'Binance Smart Chain',
    tokenName: 'BNB',
    tokenSymbol: 'BNB',
    decimals: 18,
    rpcList: ['https://bsc-dataseed.binance.org/'],
    explorerList: ['https://bscscan.com/'],
  },
  binanceTestnet: {
    id: '0x61',
    chainName: 'BSCT',
    tokenName: 'tBNB',
    tokenSymbol: 'Binance Chain Native Token',
    decimals: 18,
    rpcList: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    explorerList: ['https://bscscan.com/'],
  },
};

async function addNetwork(network) {
  const {
    id,
    chainName,
    tokenName,
    tokenSymbol,
    decimals,
    rpcList,
    explorerList,
  } = network;
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: id }],
    });
  } catch (error) {
    if (error.code === 4902) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: id,
              chainName: chainName,
              nativeCurrency: {
                name: tokenName,
                symbol: tokenSymbol,
                decimals: decimals,
              },
              rpcUrls: rpcList,
              blockExplorerUrls: explorerList,
              iconUrls: [''],
            },
          ],
        });
      } catch (addError) {
        throw new Error(`Only ${chainName} should work`);
      }
    } else throw new Error(`Web3 provider error.`);
  }
}

async function test(chain) {
  if (chain) {
    await addNetwork(networks.binanceTestnet);
    return true;
  } else {
    await addNetwork(networks.binance);
    return false;
  }
}

export class Metamask {
  constructor(contractData) {
    this.contractData = contractData;
    this.abi = contractData.abi;
    this.address = contractData.address;
    window.contract = new window.web3.eth.Contract(this.abi, this.address);
  }

  status() {
    if (window.ethereum === undefined) {
      return false;
    } else {
      return true;
    }
  }

  async getAccount(chain) {
    await test(chain);
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    const account = accounts[0];
    return account;
  }

  async balanceOf() {
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    const account = accounts[0];
    return await window.contract.methods.balanceOf(account).call(
      {
        from: account,
      },
      function (error, result) {
        if (error) {
          console.log(error);
          return undefined;
        } else {
          return result;
        }
      }
    );
  }

  async fromWei(chain) {
    await test(chain);
    const amount = await this.balanceOf();
    return Number(Web3.utils.fromWei(amount, 'ether'));
  }

  async getRewards(chain) {
    await test(chain);
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    const account = accounts[0];
    return await window.contract.methods.totalRewardsEarned(account).call(
      {
        from: account,
      },
      function (error, result) {
        if (error) {
          console.log(error);
          return error;
        } else {
          console.log('result', result);
          return result;
        }
      }
    );
  }

  async transfer(amount, chain) {
    await test(chain);
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    const account = accounts[0];
    const recipient = '0xf931897cDD92554Fb8922770A24358F6FE8400C8';
    const weiAmount = await window.web3.utils.toWei(amount);
    const params = [
      {
        from: account,
        to: recipient,
        gas: '0x' + parseInt(30400, 10).toString(16),
        gasPrice: '0x' + parseInt(10000000000, 10).toString(16),
        value: '0x' + parseInt(weiAmount, 10).toString(16),
      },
    ];
    return window.ethereum
      .request({
        method: 'eth_sendTransaction',
        params,
      })
      .then((result) => {
        return 'La transacción se ha realizado correctamente';
        // The result varies by RPC method.
        // For example, this method will return a transaction hash hexadecimal string on success.
      })
      .catch((error) => {
        return 'Se ha producido un error';
        // If the request fails, the Promise will reject with an error.
      });
  }

  async onClickConnect(chain) {
    await test(chain);
    window.ethereum.request({ method: 'eth_requestAccounts' });
  }
}
