import { useEffect, useState } from 'react';
import { Metamask, metamaskInstalled } from './helpers/metamask';
import contract from './smart-contract/contractData';
import contractTest from './smart-contract/contractDataTest';
import { useTest } from './context/hook/useTest';
import ReactGA from 'react-ga';
import './App.scss';

function PopUp({ onClick }: any) {
  const [amount, setAmount] = useState(0);
  const [result, setResult] = useState('');
  const disabled = true;
  const metamask = new Metamask(contract);

  const { test } = useTest();

  const transferTokens = async () => {
    const response = await metamask.transfer(amount, test);
    setResult(response);
    console.log('response', response);
  };

  const onChange = (e: any) => {
    const value = e.target.value;
    setAmount(value);
  };

  return (
    <div className='PopUp'>
      <button onClick={onClick} className='PopUp-close'>
        X
      </button>
      <h2 className='PopUp-title'>1ª Presale XHP</h2>
      <div className='PopUp-transfer'>
        <div className='PopUp-transfer-input'>
          <p>Mínimo 0.2 BNB | Máximo 10 BNB</p>
          <input type='number' onChange={onChange} value={amount} />
        </div>
        <button
          className='PopUp-transfer-button'
          onClick={transferTokens}
          disabled={amount >= 0.2 && amount <= 10 ? !disabled : disabled}
        >
          ENVIAR BNB
        </button>
      </div>
      <p className='PopUp-subtitle'>
        Recuerda que el Airdrop de los tokens se realizará unos minutos antes
        del lanzamiento a PancakeSwap.
      </p>
      <p className='PopUp-welcome'>¡Bienvenid@ a la familia XHYPE!</p>
      <p
        style={{
          color: result === 'Se ha producido un error' ? '#ed2a80' : '#28b996',
        }}
      >
        {result}
      </p>
    </div>
  );
}

function App() {
  const [address, setAddress] = useState('');
  const [token, setToken] = useState('');
  const [rewards, setRewards] = useState('');
  const [show, setShow] = useState(false);

  const testing = metamaskInstalled();

  const { test, setTest } = useTest();

  const metamask = new Metamask(test ? contractTest : contract);

  const connectWallet = () => metamask.onClickConnect(test);

  useEffect(() => {
    async function fetchData() {
      const XHP = await metamask.fromWei(test);
      console.log('XHP', XHP);
      const XHPDecimals = Number.parseFloat(String(XHP)).toFixed(2);
      const XHPNumber = Number(XHPDecimals);
      const XHPFormatted = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(XHPNumber);
      setToken(XHPFormatted.split('€')[0]);

      const USDT = await metamask.getRewards(test);
      const USDTDecimals = Number.parseFloat(String(USDT)).toFixed(2);
      const USDTNumber = Number(USDTDecimals);
      const USDTFormatted = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(USDTNumber);
      setRewards(USDTFormatted.split('€')[0]);

      setAddress(await metamask.getAccount(test));
    }
    fetchData();
  }, [metamask]);

  const onClick = () => {
    setShow(!show);
  };

  const modeTest = () => {
    setTest(!test);
  };

  useEffect(() => {
    ReactGA.initialize('UA-240346962-1');
  }, []);

  useEffect(() => {
    ReactGA.pageview('/');
  }, []);

  return (
    <div className='container'>
      <header className='App-header'>
        <a href='https://xhype.io/' target='_blank' rel='noreferrer'>
          <img height='40' src='App/logo.png' alt='' />
        </a>
        <div className='App-header-menu'>
          <div className='App-header-menu-item'>
            <img
              src='./App/coming.png'
              alt='coming-soon'
              className='App-header-menu-ico-pre1'
            />
            <div className='App-header-menu-ico-div'>
              <img
                className='App-header-menu-ico-size'
                src='./App/deposit.png'
                alt='staking'
              />
              <span className='App-header-menu-label'>Staking</span>
            </div>
          </div>
          <div className='App-header-menu-item'>
            <img
              src='./App/coming.png'
              alt='coming-soon'
              className='App-header-menu-ico-pre1'
            />
            <div className='App-header-menu-ico-div'>
              <img
                className='App-header-menu-ico'
                src='./App/nft.png'
                alt='marketplace'
              />
              <span className='App-header-menu-label'>Marketplace</span>
            </div>
          </div>
          <div className='App-header-menu-item'>
            <img
              src='./App/coming.png'
              alt='coming-soon'
              className='App-header-menu-ico-pre1'
            />
            <div className='App-header-menu-ico-div'>
              <img
                className='App-header-menu-ico'
                src='./App/vote.png'
                alt='dao'
              />
              <span className='App-header-menu-label'>DAO</span>
            </div>
          </div>
          {/* <button className='App-header-menu-item' onClick={onClick}>
            <img
              src='./App/check.png'
              alt='check'
              className='App-header-menu-ico-pre'
            />
            <div className='App-header-menu-ico-div'>
              <img
                className='App-header-menu-ico'
                src='./App/bnb.png'
                alt='presale'
              />
              <span className='App-header-menu-label'>PRESALE</span>
            </div>
          </button> */}
        </div>
        <div className='App-header-wallet'>
          <div className='App-header-wallet-info'>
            <div className='wallet-item'>
              <span className='wallet-ico wallet-ico-xhype'></span>
              <span className='wallet-label'>{token} XHP</span>
            </div>

            <div className='wallet-item'>
              <span className='wallet-ico wallet-ico-wallet'></span>

              {address === '' && testing === 'Metamask no instalado' && (
                <span className='wallet-label'>
                  <a
                    href='https://metamask.io/download/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Instalar metamask
                  </a>
                </span>
              )}
              {address === '' && testing !== 'Metamask no instalado' && (
                <button className='App-connectWallet' onClick={connectWallet}>
                  Conectar wallet
                </button>
              )}
              {address !== '' && testing !== 'Metamask no instalado' && (
                <span className='wallet-label'>
                  {address.slice(0, 9)}...{address.slice(-4)}
                </span>
              )}
            </div>

            <span className='App-header-wallet-info-icon'></span>
          </div>

          <div className='App-header-wallet-rewards'>
            <span className='App-header-wallet-rewards-label'>
              Recompensas generadas:
            </span>
            <div className='wallet-item wallet-item-big'>
              <span className='wallet-ico wallet-ico-usdt'></span>
              <span className='wallet-label'>{rewards} USDT</span>
            </div>
          </div>
        </div>
      </header>
      <main className='App-main'>
        <div className='App-main-text'>
          <div className='App-main-text-content'>
            <span className='App-main-title'>¡Bienvenid@ XHYPER!</span>
            <span className='App-main-subtitle'>
              Genera ingresos pasivos estables
            </span>
            {/* <div className='App-modeTest'>
              <button
                onClick={modeTest}
                style={{ backgroundColor: test ? '#28b995' : 'grey' }}
              >
                Modo Test
              </button>
            </div> */}
          </div>
        </div>
        <img className='App-hand' width='239' src='App/hand.png' alt='' />
        <div className='App-purple-elipse'></div>
        <div className='App-pink-elipse'></div>
        <div className='App-blue-elipse'></div>
      </main>
      <p className='App-footer'>
        Copyright © | Todos los derechos reservados a xhype.io
      </p>
      {show && (
        <div className='App-popUp'>
          <PopUp onClick={onClick} />
        </div>
      )}
    </div>
  );
}

export default App;
