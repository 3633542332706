import React from "react";
import { useState, createContext } from "react";

interface IContext {
  children: any;
}

export const TestContext = createContext<any>({
  test: false
});

export const TestProvider = ({ children }: IContext): any => {
  const [test, setTest] = useState<any>(false);

  const values: any = {
    test, setTest
  };

  return <TestContext.Provider value={values}>{children}</TestContext.Provider>;
};